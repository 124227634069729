import spotify from "../assets/images/spotify.png";
import google from "../assets/images/google.png";
import uber from "../assets/images/Uber.png";
import microsoft from "../assets/images/microsoft.png";
import shopify from "../assets/images/shopify.png";
import evernote from "../assets/images/evernote.png";
import adobe from "../assets/images/adobe.png";
import paypal from "../assets/images/paypal.png";
import amazon from "../assets/images/amazon.png";
import asana from "../assets/images/asana.png";
import contact from "../assets/images/contact.svg";
import analysis from "../assets/images/analysis.svg";
import offer from "../assets/images/offer.svg";
import team from "../assets/images/team.svg";
import start from "../assets/images/start.svg";
import logo from "../assets/images/icon_logo.jpg";
import bg_hero from "../assets/images/bg_hero.png";
import icon_video from "../assets/images/icon_video.png";
import icon_brand from "../assets/images/icon_brand.png";
import icon_design from "../assets/images/icon_design.png";
import icon_UXUI from "../assets/images/icon_UXUI.png";
import icon_crm from "../assets/images/icon_crm.png";
import airplane from "../assets/images/airplane.png";

export default {
  spotify,
  google,
  uber,
  microsoft,
  shopify,
  evernote,
  adobe,
  paypal,
  amazon,
  asana,
  contact,
  analysis,
  offer,
  team,
  start,
  logo,
  bg_hero,
  icon_video,
  icon_brand,
  icon_design,
  icon_UXUI,
  icon_crm,
  airplane,
};
